import { Slider } from '@finn/ui-modules';

import {
  ContentItemCosmicMetadata,
  UIBaseCosmicObject,
} from '~/types/UIModules';
import ContentItem from '~/ui-elements/ContentItem';

type Props = {
  title: string;
  headingSize?: 1 | 2 | 3 | 4;
  lineLimit?: number;
  contents: UIBaseCosmicObject<ContentItemCosmicMetadata>[];
};

const ContentSlider = ({
  title,
  lineLimit,
  headingSize = 3,
  contents,
}: Props) => {
  return (
    <Slider title={title} titleHeadingSize={headingSize}>
      {contents?.map((content) => (
        <div className="h-full w-[280px] sm:w-[360px]" key={content.id}>
          <ContentItem lineLimit={lineLimit} item={content.metadata} />
        </div>
      ))}
    </Slider>
  );
};

export default ContentSlider;
